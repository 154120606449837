
import React from 'react';
import Hero from 'blocks/Hero';
import DefinitionList from 'blocks/DefinitionList';
import Default from 'blocks/Default';

const availableBlocks = {
  'Hero': Hero,
'DefinitionList': DefinitionList,
};

const Blocks = ({block}) => {
    const blockName = block.__typename.replace(/.*_([a-z]*)$/gi, '$1');

    return availableBlocks[blockName]
        ?  React.createElement(availableBlocks[blockName], {Blocks, ...block})
        : <Default blockType={blockName}/>;
};

export default Blocks;
